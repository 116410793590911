import { Component, OnInit } from '@angular/core';
import { PagesService } from '../services/pages.service';
import { SettingsConstants } from '../common/settings-constants';
import { Title } from '@angular/platform-browser';
import { PensionService } from '../services/pension.service';

@Component({
  selector: 'app-pension',
  templateUrl: './pension.component.html',
  styleUrls: ['./pension.component.css']
})
export class PensionComponent implements OnInit {
  public page = {banner_image : "", title : "", content_heading : "", content_sub_heading : "", meta_title : "Pension | Closternam",meta_desc : "Closternam",'content_body' : ""};
  SettingsConstant:any = SettingsConstants;
  public variousPensions=[];

  constructor(private page_service:PagesService, private title: Title, private pension_service: PensionService) { }

  ngOnInit() {
    this.getPageDetails();
    this.getPensionDetails();
  }

  getBackgroundImageUrl(){
    return `url('../../assets/images/Rectangle 2.png')`;
  }

  getPageDetails(){
    this.page_service.getCms('pension')
    .subscribe(response => {
      this.page=response;
      this.title.setTitle(this.page.meta_title);
    });
  }
  getPensionDetails(){
    this.pension_service.getPension()
    .subscribe(response => {
      this.variousPensions=response;
    })
  }

}
